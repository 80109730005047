import React, { PureComponent } from 'react'
import { withPrefix } from 'gatsby'
import browserLang from 'browser-lang'
import { languages, defaultLanguage } from './index'

class Redirect extends PureComponent {
  constructor(props) {
    super(props)

    const langKeys = languages.map(language => language.value)
    const { pathname } = props.location

    // Browser only
    if (typeof window !== 'undefined') {
      const detected =
        window.localStorage.getItem('language') ||
        browserLang({
          languages: langKeys,
          fallback: defaultLanguage.value
        })

      const newUrl = withPrefix(`/${detected}${pathname}`)
      window.localStorage.setItem('language', detected)
      window.location.replace(newUrl)
      }
    }

    render() {
      return <div />
    }
}

export default Redirect